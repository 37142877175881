import request from '@/utils/request'

export function listApi(data) {
    return request({
        url: '/advert/busniessGroup/list',
        method: 'post',
        data
    })
}

export function addApi(data) {
    return request({
        url: '/advert/busniessGroup/add',
        method: 'post',
        data
    })
}

export function updateApi(data) {
    return request({
        url: '/advert/busniessGroup/edit',
        method: 'post',
        data
    })
}
export function delApi(data) {
    return request({
        url: '/advert/busniessGroup/del',
        method: 'post',
        data
    })
}


export function listAllCompany(data) {
    return request({
        url: '/advert/busniessGroup/listAllCompany',
        method: 'post',
        data
    })
}
