import request from '@/utils/request'

export function listApi(data) {
    return request({
        url: '/advert/business/list',
        method: 'post',
        data
    })
}

export function addApi(data) {
    return request({
        url: '/advert/business/add',
        method: 'post',
        data
    })
}

export function updateApi(data) {
    return request({
        url: '/advert/business/edit',
        method: 'post',
        data
    })
}
export function delApi(data) {
    return request({
        url: '/advert/business/del',
        method: 'post',
        data
    })
}

export function listAllBusiness(data) {
    return request({
        url: '/advert/business/listAllBusiness',
        method: 'post',
        data
    })
}

